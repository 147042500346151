import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UsersEffects, UsersFacade, usersFeatureKey, usersReducer } from '../store';

export function provideUsersDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(usersFeatureKey, usersReducer)),
    importProvidersFrom(EffectsModule.forFeature([UsersEffects])),
    makeEnvironmentProviders([UsersFacade]),
  ];
}
