import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PageResponse } from '@core/shared/domain';
import { IdentityUserListItem } from '@mp/kernel/users/domain';

export const UsersActions = createActionGroup({
  source: 'Kernel - Users',
  events: {
    fetchUsers: props<{ searchTerm?: string }>(),
    fetchUsersSuccess: props<{ usersPageResponse: PageResponse<IdentityUserListItem> }>(),
    fetchUsersError: emptyProps(),

    upsertUserToList: props<{ user: IdentityUserListItem }>(),
  },
});
