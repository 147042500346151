import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IdentityUserListItem } from '@mp/kernel/users/domain';

import { UsersActions } from './users.actions';

export const usersFeatureKey = 'mpk-core-users';

type UsersEntityState = EntityState<IdentityUserListItem>;

export const usersEntityAdapter = createEntityAdapter<IdentityUserListItem>({
  selectId: ({ userId }: IdentityUserListItem) => userId,
});

export interface UsersState {
  users: UsersEntityState;
  usersLoaded: boolean;
}

export const initialState: UsersState = {
  users: usersEntityAdapter.getInitialState(),
  usersLoaded: true,
};

export const usersReducer = createReducer(
  initialState,
  on(
    UsersActions.fetchUsers,
    (state: UsersState): UsersState => ({
      ...state,
      users: usersEntityAdapter.getInitialState(),
      usersLoaded: false,
    }),
  ),
  on(
    UsersActions.fetchUsersSuccess,
    (state: UsersState, { usersPageResponse: { data } }): UsersState => ({
      ...state,
      users: usersEntityAdapter.setAll(data, state.users),
      usersLoaded: true,
    }),
  ),
  on(
    UsersActions.fetchUsersError,
    (state: UsersState): UsersState => ({
      ...state,
      users: usersEntityAdapter.getInitialState(),
      usersLoaded: true,
    }),
  ),

  on(
    UsersActions.upsertUserToList,
    (state: UsersState, { user }): UsersState => ({
      ...state,
      users: usersEntityAdapter.upsertOne(user, state.users),
    }),
  ),
);

export const { selectAll: selectAllUsers } = usersEntityAdapter.getSelectors();
