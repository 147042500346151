import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';

import { IdentityUserListItem } from '@mp/kernel/users/domain';

import { UsersActions } from './users.actions';
import { selectUsers, selectUsersLoaded } from './users.selectors';

@Injectable()
export class UsersFacade {
  readonly users: Signal<IdentityUserListItem[]> = this.store$.selectSignal(selectUsers);

  readonly usersLoaded: Signal<boolean> = this.store$.selectSignal(selectUsersLoaded);

  constructor(private readonly store$: Store) {}

  fetchUsers(searchTerm?: string): void {
    this.store$.dispatch(UsersActions.fetchUsers({ searchTerm }));
  }

  upsertUserToList(user: IdentityUserListItem): void {
    this.store$.dispatch(UsersActions.upsertUserToList({ user }));
  }
}
